/** @format */

import { DEFAULT_BOOKING } from '@/ducks/bookingNew/constants';
import { BookingErrorStates, TReducerBooking } from '@/ducks/bookingNew/types';
import { TBookingAction } from '@/redux/declarations';

export const reducerBooking = (
  prevState: TReducerBooking = DEFAULT_BOOKING,
  action: TBookingAction,
): TReducerBooking => {
  switch (action.type) {
    case 'BOOKING_SET_ROOM':
      return {
        ...prevState,
        room: action.payload,
      };
    case 'BOOKING_AVAILABILITY_REQUEST':
      return {
        ...prevState,
        loading: prevState.loading + 1,
        loadingAvailability: true,
      };
    case 'BOOKING_CONTRACTUAL_REQUEST':
    case 'BOOKING_COMMERCIAL_REQUEST':
      return {
        ...prevState,
        loading: prevState.loading + 1,
      };
    case 'BOOKING_OPTIONS_REQUEST':
      return {
        ...prevState,
        loading: prevState.loading + 1,
      };
    case 'BOOKING_LIGHT_IDENTITY_REQUEST':
      return { ...prevState, loading: prevState.loading + 1 };
    case 'BOOKING_LIGHT_IDENTITY_SUCCESS':
      return {
        ...prevState,
        loading: prevState.loading - 1,
        lightIdentity: action.payload,
        errors: {
          ...prevState.errors,
          lightIdentity: undefined,
        },
      };
    case 'BOOKING_LIGHT_IDENTITY_FORM_FAILURE':
      return {
        ...prevState,
        loading: prevState.loading - 1,
        errors: {
          ...prevState.errors,
          lightIdentity: action.payload,
        },
      };
    case 'BOOKING_LIGHT_IDENTITY_FAILURE':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          contactDetailsErrors: action.payload,
        },
      };
    case 'BOOKING_VALIDATE_ROOM_REQUEST':
      return {
        ...prevState,
        loading: prevState.loading + 1,
      };
    case 'BOOKING_VALIDATE_ROOM_SUCCESS':
      return {
        ...prevState,
        loading: prevState.loading - 1,
      };
    case 'BOOKING_AVAILABILITY_SUCCESS':
      return {
        ...prevState,
        availability: action.payload,
        loading: prevState.loading - 1,
        loadingAvailability: false,
      };
    case 'BOOKING_OPTIONS_SUCCESS':
      return {
        ...prevState,
        bookingOptions: action.payload,
        loading: prevState.loading - 1,
      };
    case 'BOOKING_CONTRACTUAL_SUCCESS':
      return {
        ...prevState,
        contractualConditions: action.payload,
        loading: prevState.loading - 1,
      };
    case 'BOOKING_COMMERCIAL_SUCCESS':
      return {
        ...prevState,
        commercialConditions: action.payload,
        loading: prevState.loading - 1,
      };
    case 'BOOKING_VALIDATE_ROOM_FAILURE':
    case 'BOOKING_AVAILABILITY_FAILURE':
      return {
        ...prevState,
        error: BookingErrorStates.NO_AVAILABILITY,
        loading: prevState.loading - 1,
        loadingAvailability: false,
      };
    case 'BOOKING_CONTRACTUAL_FAILURE':
    case 'BOOKING_COMMERCIAL_FAILURE':
    case 'BOOKING_OPTIONS_FAILURE':
    case 'BOOKING_GENERIC_FAILURE':
      return {
        ...prevState,
        error: BookingErrorStates.GENERIC_API,
        loading: prevState.loading - 1,
      };
    case 'BOOKING_SET_CHECK_IN_DATE':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          checkInDate: action.payload,
        },
      };
    case 'BOOKING_SET_CHECK_OUT_DATE':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          checkOutDate: action.payload,
        },
      };
    case 'BOOKING_SET_CONTACT_DETAILS':
      return {
        ...prevState,
        step: 'personalDetails',
        applicationForm: {
          ...prevState.applicationForm,
          contactDetails: action.payload,
        },
      };
    case 'BOOKING_SET_PERSONAL_DETAILS_FORM_ERROR':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          personalDetailsErrors: action.payload,
        },
      };
    case 'BOOKING_SET_PERSONAL_DETAILS_ERROR':
      return {
        ...prevState,
        errors: {
          ...prevState.errors,
          personalDetails: action.payload,
        },
      };
    case 'BOOKING_SET_SECOND_OCCUPANT':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          secondOccupant: action.payload,
        },
      };
    case 'BOOKING_SET_SECOND_OCCUPANT_FORM_ERROR':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          secondOccupantErrors: action.payload,
        },
      };
    case 'BOOKING_SET_SECOND_OCCUPANT_ERROR':
      return {
        ...prevState,
        errors: {
          ...prevState.errors,
          secondOccupant: action.payload,
        },
      };
    case 'BOOKING_SET_RESIDENTIAL_ADDRESS':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          residentialAddress: action.payload,
        },
      };
    case 'BOOKING_SET_RESIDENTIAL_ADDRESS_FORM_ERROR':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          residentialAddressErrors: action.payload,
        },
      };
    case 'BOOKING_SET_RESIDENTIAL_ADDRESS_ERROR':
      return {
        ...prevState,
        errors: {
          ...prevState.errors,
          residentialAddress: action.payload,
        },
      };
    case 'BOOKING_SET_FINANCIAL_VALIDATION':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          financialValidation: action.payload,
        },
      };
    case 'BOOKING_SET_FINANCIAL_VALIDATION_FORM_ERROR':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          financialValidationErrors: action.payload,
        },
      };
    case 'BOOKING_SET_FINANCIAL_VALIDATION_ERROR':
      return {
        ...prevState,
        errors: {
          ...prevState.errors,
          financialValidation: action.payload,
        },
      };
    case 'BOOKING_SET_VALID_CHECKOUT':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          validCheckOutDates: action.payload,
        },
      };
    case 'BOOKING_SET_STEP': {
      return {
        ...prevState,
        step: action.payload,
      };
    }
    case 'BOOKING_SET_HOW_FIND_US': {
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          howDidYouFindUs: action.payload,
        },
      };
    }
    case 'BOOKING_SET_HOW_FIND_US_FORM_ERROR':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          howDidYouFindUsErrors: action.payload,
        },
      };
    case 'BOOKING_SET_HOW_FIND_US_ERROR': {
      return {
        ...prevState,
        errors: {
          ...prevState.errors,
          howDidYouFindUs: action.payload,
        },
      };
    }
    case 'BOOKING_ACTIVATE_PAYMENT_STEP': {
      return {
        ...prevState,
        step: 'payment',
      };
    }
    case 'BOOKING_CREATE_SUCCESS': {
      return {
        ...prevState,
        step: 'payment',
        paymentInfo: action.payload,
      };
    }
    case 'BOOKING_CREATE_FAILURE': {
      return {
        ...prevState,
        errors: {
          ...prevState.errors,
          bookingComplete: action.payload,
        },
      };
    }
    default:
      return prevState;
  }
};
